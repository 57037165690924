<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">
        <ContactForm />
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import ContactForm from './../partials/ContactForm.vue'
import Footer from './../partials/Footer.vue'
import Header from './../partials/Header.vue'
export default {
  name: 'GeneralContactView',
  components: {
    ContactForm,
    Footer,
    Header
  },
  created(){
    document.title="Contacto | Renta Dominios"
  }
}
</script>