<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import AOS from 'aos'
import Sticky from 'sticky-js'
import { focusHandling } from 'cruip-js-toolkit'

export default {
  name: 'App',
  mounted() {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
    // eslint-disable-next-line no-unused-vars
    const sticky = new Sticky('[data-sticky]');    
    focusHandling()
    // Route change
    if (this.$router) {
      this.$watch('$route', () => {
        // eslint-disable-next-line no-unused-vars
        const sticky = new Sticky('[data-sticky]'); 
        focusHandling('outline')
      });
    }    
  }  
};
</script>
