<template>
  <section class="relative">

    <!-- Section background (needs .relative class on parent and next sibling elements) -->
    <div class="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
    <div class="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-12 md:pt-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h1 class="h2 mb-4">Adaptable a tus necesidades</h1>
          <p class="text-xl text-gray-600 text-justify">
            Para mejorar tu captación de clientes es recomendable tener un buen dominio que se adecué a tu negocio y lo que vendes. 
             El tener un dominio premium te ayudará a llegar a tus metas.
          </p>
        </div>

        <!-- Section content -->
        <div class="md:grid md:grid-cols-12 md:gap-6">

          <!-- Content -->
          <div class="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:my-12">
            <div class="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
              <h3 class="h3 mb-3">Crece tu empresa</h3>
            </div>
            <!-- Tabs buttons -->
            <div class="mb-8 md:mb-0">
              <a :class="tab !== '1' ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'" class="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3" href="#0" @click.prevent="tab = '1'">
                <div>
                  <div class="font-bold leading-snug tracking-tight mb-1">Mejora tu imagen</div>
                  <div class="text-gray-600">Aumenta la confianza con tus clientes y permíteles recordar facilmente donde encontrarte.</div>
                </div>
                <div class="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg class="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                  </svg>
                </div>
              </a>
              <a :class="tab !== '2' ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'" class="flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3" href="#0" @click.prevent="tab = '2'">
                <div>
                  <div class="font-bold leading-snug tracking-tight mb-1">Aumenta tu visibilidad</div>
                  <div class="text-gray-600">Entre más sencillo sea el nombre de tu dominio, mayor visibilidad tendrás ante los buscadores como Bing, Google, etc. </div>
                </div>
                <div class="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow flex-shrink-0 ml-3">
                  <svg class="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fill-rule="nonzero" />
                  </svg>
                </div>
              </a>
            </div>
          </div>

          <!-- Tabs items -->
          <div class="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="zoom-y-out">
            <div class="relative flex flex-col text-center lg:text-right">
              <!-- Item 1 -->
              <transition
                enter-active-class="transition ease-in-out duration-700 transform order-first"
                enter-class="opacity-0 translate-y-16"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in-out duration-300 transform absolute"
                leave-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-16"
              >
                <div class="w-full"  v-show="tab === '1'">
                  <div class="relative inline-flex flex-col">
                    <img class="md:max-w-none mx-auto rounded" :src="require('@/images/improvement-1.jpg')" width="500" height="375" alt="Features bg" />
                    <div 
                      class="md:max-w-none absolute bg-blue-900 rounded-lg py-2 px-2 flex transform animate-float animation-delay-1000" 
                      style="top:7%; right: auto; left: 8%;"
                    >
                      <svg class="h-5 w-5 text-teal-500 my-auto ml-0 mr-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
                      </svg>
                      <p class="text-gray-100 text-sm px-2 font-medium">Conecta mejor con tu público</p>
                    </div>
                    <div 
                      class="md:max-w-none absolute bg-gray-100 rounded-lg py-2 px-2 flex transform animate-float animation-delay-1000" 
                      style="top:82%; right: 4%; left: auto;"
                    >
                      <p class="text-gray-800 text-sm px-2">Escala a tu medida</p>
                      <svg class="h-5 w-5 text-blue-500 my-auto ml-0 mr-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- Item 2 -->
              <transition
                enter-active-class="transition ease-in-out duration-700 transform order-first"
                enter-class="opacity-0 translate-y-16"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in-out duration-300 transform absolute"
                leave-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-16"
              >
                <div class="w-full"  v-show="tab === '2'">
                  <div class="relative inline-flex flex-col">
                    <img class="md:max-w-none mx-auto rounded" :src="require('@/images/visibility-3.jpg')" width="500" height="375" alt="Features bg" />
                    <div 
                      class="md:max-w-none absolute bg-gray-100 rounded-lg py-2 px-2 flex transform animate-float animation-delay-1000" 
                      style="top:7%; right: 2%; left: auto;"
                    >
                      <p class="text-blue-700 text-sm px-2 my-auto">Crea una experiencia memorable</p>
                      <svg class="h-5 w-5 text-gray-700 mb-auto ml-0 mr-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg>
                    </div>
                    <div 
                      class="md:max-w-none absolute bg-teal-900 rounded-lg py-2 px-2 flex transform animate-float animation-delay-1000" 
                      style="top:82%; right: auto; left: 5%;"
                    >
                      <svg class="h-5 w-5 text-blue-500 my-auto ml-0 mr-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                      <p class="text-gray-100 text-sm px-2 font-medium">Genera prospectos fácilmente</p>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesHome',
  data: function () {
    return {
      tab: '1',
    }
  },
}
</script>