<template>
  <section class="bg-gradient-to-b from-white to-gray-100">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pb-12 md: md:pb-20">
        <!-- Pricing tables -->
            <!-- Section header -->
            <div class="max-w-3xl mx-auto text-center pb-12">
                <h1 class="h1 mb-4" data-aos="zoom-y-out">{{domainName}}</h1>
                <p class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">*Todos nuestros precios están en dólares*</p>
            </div>
        <div>
          <!-- Pricing toggle -->
          <div class="flex justify-center max-w-xs m-auto mb-16" data-aos="zoom-y-out" data-aos-delay="300">
            <div class="relative flex w-full mx-6 p-1 bg-gray-200 rounded">
              <span class="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
                <span class="absolute inset-0 w-1/2 bg-white rounded shadow transform transition duration-150 ease-in-out" :class="value ? 'translate-x-0' : 'translate-x-full'"></span>
              </span>
              <button class="relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out" :class="{'text-gray-500':!value}" @click.prevent="value = true">Pago Anual <span class="text-green-500">-20%</span>
              </button>
              <button class="relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out" :class="{'text-gray-500':value}" @click.prevent="value = false">Pago mensual</button>
            </div>
          </div>

          <div class="max-w-sm md:max-w-2xl xl:max-w-none mx-auto md:grid-cols-2 xl:grid-cols-4 xl:gap-6 items-start">

            <!-- Pricing table 1 -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
              <div class="mb-4">
                <div class="inline-flex items-baseline mb-2">
                  <span class="text-3xl font-bold">{{priceOutput[value][0]}}</span>
                  <span class="text-4xl font-bold">{{priceOutput[value][1]}}</span>
                  <span class="text-gray-600 pl-2">{{priceOutput[value][2]}}</span>
                </div>
                <div class="text-lg text-gray-800">Renta tu dominio 
                  <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">Premium </span>
                   y obtén los siguientes beneficios</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Tendrás una imagen más profesional de tu marca.</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Aumentarás tu posicionamiento en la web.</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Tus clientes te recordarán de una manera más simple y clara.</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Ayudarás a promover tu sitio web de una manera más adecuada.</span>
                </li>
              </ul>
              <router-link to="/contact">
                <div class="border-t border-gray-200 pt-5 mt-6 w-1/2 mx-auto">
                  <a class="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full" href="#0">Contáctanos</a>
                </div>
              </router-link>
            </div>

          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PricingTables',
  props: {
    domainName: String,
    priceOutput: {
        false: [String, String, String],
        true: [String, String, String]
    },
  },
  data: function () {
    return {
      value: true,
    }
  },
}
</script>