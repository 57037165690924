import Vue from 'vue'
import VueRouter from 'vue-router'
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)
import App from './App.vue'

import '@/css/style.scss';

Vue.use(VueRouter)
import Home from './pages/Home.vue'
import TermsOfService from './pages/Terms.vue'
import Privacy from './pages/Privacy.vue'
import Tutorials from './pages/Tutorials.vue'
import Pricing from './pages/Pricing.vue'
import Blog from './pages/Blog.vue'
import BlogPost from './pages/BlogPost.vue'
import About from './pages/About.vue'
import Documentation from './pages/Documentation.vue'
import Support from './pages/Support.vue'
import SignIn from './pages/SignIn.vue'
import SignUp from './pages/SignUp.vue'
import ResetPassword from './pages/ResetPassword.vue'
import PageNotFound from './pages/PageNotFound.vue'
import vuetify from './plugins/vuetify';
import GeneralContactView from './pages/GeneralContact.vue';
import WhatDomainName from './pages/BlogPosts/WhatDomainName.vue';
import TipsGoodDomainName from './pages/BlogPosts/TipsGoodDomainName.vue';
//import ProsRentingDomain from './pages/BlogPosts/ProsRentingDomain.vue';

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/tutorials',
    component: Tutorials
  },
  {
    path: '/pricing',
    component: Pricing
  },
  {
    path: '/contact',
    component: GeneralContactView
  },
  {
    path: '/blog',
    component: Blog
  },
  {
    path: '/terminos',
    component: TermsOfService
  },
  {
    path: '/privacidad',
    component: Privacy
  },
  {
    path: '/blog/what-domain-name',
    component: WhatDomainName
  },
  {
    path: '/blog/tips-great-name',
    component: TipsGoodDomainName
  },
  /*
  {
    path: '/blog/pros-rent-domain',
    component: ProsRentingDomain
  },*/
  { 
    path: '/blog-post',
    component: BlogPost
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/documentation',
    component: Documentation
  },
  {
    path: '/support',
    component: Support
  },
  {
    path: '/signin',
    component: SignIn
  },
  {
    path: '/signup',
    component: SignUp
  },
  {
    path: '/reset-password',
    component: ResetPassword
  },                  
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to) {
    console.log(to)
    return new Promise(() => {
      if (!to.hash) {
        window.scroll({ top: 0 })
      } else {
        return {top: document.getElementById(to.hash.replace("#", "")).offsetTop}
      }
    })
  }  
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
