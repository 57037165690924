<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">
            <div class="max-w-3xl mx-auto lg:max-w-none">
              <article>
                <!-- Article header -->
                <header class="max-w-3xl mx-auto mb-20">
                  <!-- Title -->
                  <h1 class="h1 text-center mb-2">10 consejos para elegir un buen nombre de dominio</h1>
                </header>
                <!-- Article content -->
                <div class="lg:flex lg:justify-between" data-sticky-container>

                  <!-- Sidebar -->
                  <aside class="relative hidden lg:block w-64 mr-20 flex-shrink-0">
                    <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>
                      <h4 class="text-lg font-bold leading-snug tracking-tight mb-4">Tabla de contenidos</h4>
                      <ul class="font-medium -my-1">
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#introduction">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Introducción</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-1">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Dominio disponible</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-2">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>No puedes registrar cualquier nombre</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-3">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Search Engine Optimization (SEO)</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-4">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Diferenciador</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-5">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Fácil de recordar</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-6">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Sencillo de escribir</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-7">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Cuida el significado de tu dominio</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-8">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Que se fácil de pronunciar</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-9">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Escoge bien tu extensión</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#tip-10">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Metas y objetivos</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </aside>

                  <!-- Main content -->
                  <div>

                    <!-- Article meta -->
                    <div class="flex items-center mb-6">
                      <div class="flex flex-shrink-0 mr-3">
                        <a class="relative" href="#0">
                          <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                          <img class="relative rounded-full" :src="require('@/images/logo-1.png')" width="32" height="32" alt="Author 04" />
                        </a>
                      </div>
                      <div>
                        <span class="text-gray-600">Por </span>
                        <a class="font-medium">Renta Dominios</a>
                        <span class="text-gray-600"> · 8 de Diciembre, 2020</span>
                      </div>
                    </div>
                    <hr class="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                    <!-- Article body -->
                    <div class="text-lg text-gray-600">
                      <p id="introduction" class="mb-8" style="scroll-margin-top: 100px;">
                        Cuando pensamos en abrir un proyecto nuevo o negocio, es necesario pensar en digital, es importante que nuestros clientes nos encuentren de una forma rápida y fácil. 
                        El nombre de nuestro dominio será nuestra marca personal, con lo que los clientes nos asociarán.
                      </p>
                      <p class="mb-8 text-justify">
                        El dominio es solo una dirección en palabras fáciles de recordar, es mas fácil acceder a nuestra página usando un nombre que recordando la dirección IP de la misma. 
                        Es por eso que debemos de buscar el dominio que mejor se adapte a nuestras necesidades.
                      </p>
                      <p class="mb-8 text-justify">
                        Recordemos que el dominio será nuestra marca en donde los usuarios nos van a identificar y así será más fácil para ellos buscarnos, ya que nuestra marca y nuestro 
                        dominio deberían de ir de la mano y tener prácticamente el mismo nombre.
                      </p>
                      <figure class="mb-8">
                        <img class="w-full rounded" :src="require('@/images/blog/tipsDomainName/computerPinkBG.jpg')" width="768" height="432" alt="Blog single" />
                      </figure>

                      <!--Secciones-->
                      <h4 id="tip-1" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">1. </span> Dominio disponible
                      </h4>
                      <p class="mb-8 text-justify">
                        Lo más importante en estos momentos es saber si el nombre del dominio que buscamos está disponible para poder usarlo, existen diferentes páginas verificadoras en las 
                        que podemos ver si éste está disponible.
                      </p>
                      <p class="mb-8 text-justify">
                        Te sugerimos revisar primero nuestro catálogo de dominios antes de revisar en páginas de terceros si está disponible.
                      </p>
                      <p class="mb-8 text-justify">
                        Si alguien más es dueño del dominio que deseas usar, en algunas páginas verificadoras puedes contactarlo, para poder hacer una oferta por el dominio que quieres 
                        utilizar, de esta forma podrás saber si éste se encuentra en renta o en venta. 
                      </p>

                      <h4 id="tip-2" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">2. </span> No puedes registrar cualquier nombre
                      </h4>
                      <p class="mb-8 text-justify">
                        Recuerda que no todos los nombres los puedes registrar, hay nombres que ya se encuentran registrados y no están disponibles para compra o venta. 
                      </p>
                      <p class="mb-8 text-justify">
                        Adicionalmente no todos los dominios existen en la web, y no todas las extensiones pueden ser utilizadas para un dominio, por lo mismo sugerimos comenzar a buscar y contemplar 
                        opciones alternas.
                      </p>

                      <h4 id="tip-3" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">3. </span> Search Engine Optimization (SEO)
                      </h4>
                      <p class="mb-8 text-justify">
                        Una de las cosas más importantes a la hora de elegir tu nombre es lo que conocemos como <b class="text-blue-800">Search Engine Optimization</b> (SEO), 
                        ya que esto nos ayudará a realizar campañas de posicionamiento y así tomar ventaja ante tu competencia.
                      </p>
                      <p class="mb-8 text-justify">
                        Recordemos la importancia de aparecer primero en los buscadores, para lo cual es mejor tener un dominio que contenga palabras clave o palabras que son más comunes dentro de nuestros usuarios 
                        y eso nos ayudará a estar mejor situados frente a nuestra competencia.
                      </p>

                      <h4 id="tip-4" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">4. </span> Diferenciado
                      </h4>
                      <p class="mb-8 text-justify">
                        Recuerda usar un dominio lo más genérico posible, ya que tus clientes podrán localizarte de una manera más fácil y encontrar 
                        tus productos y/o servicios, lo más importante es que puedas tener un diferenciador frente a tu competencia.
                      </p>

                      <h4 id="tip-5" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">5. </span> Fácil de recordar
                      </h4>
                      <p class="mb-8 text-justify">
                        Te recomendamos que dentro de tu dominio no utilices palabras difíciles de recordar (a menos que así se llame tu marca), el usar palabras glamurosas o que tengan anglicismos difíciles de 
                        recordar, hará que sea más difícil para tus clientes encontrarte.
                      </p>
                      <p class="mb-8 text-justify">
                        Recuerda que el nombre de tu dominio debe de ir de la mano con el nombre de tu marca.
                      </p>
                      <figure class="mb-8">
                        <img class="w-full rounded" :src="require('@/images/blog/tipsDomainName/thinkingComputer.jpg')" width="768" height="432" alt="Blog single" />
                      </figure>

                      <h4 id="tip-6" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">6. </span> Sencillo de escribir
                      </h4>
                      <p class="mb-2">
                        Una de las cosas importantes es que el nombre de tu dominio no presente confusiones, por lo general al dar a conocer nuestro dominio lo hacemos de forma fonética, 
                      </p>
                      <p class="mb-8 text-justify">
                        Que sea fácil de escribir ayudará a nuestros clientes a que lo recuerden en cualquier momento y que sea fácil de escribir en el explorador web.
                      </p>

                      <h4 id="tip-7" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">7. </span> Cuida el significado de tu dominio
                      </h4>
                      <p class="mb-8 text-justify">
                        Recuerda que las palabras que uses escogiendo tu dominio deben de ser palabras que no tengan términos negativos o que tengan doble sentido, ya que 
                        puede afectar a tu marca.
                      </p>
                      
                      <h4 id="tip-8" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">8. </span> Que sea fácil de pronunciar
                      </h4>
                      <p class="mb-8 text-justify">
                        Presta atención a la sonoridad y pronunciación de tu dominio. Debe de ser sencillo de decir, como lo vimos en el punto número 6 y así no existan equivocaciones.
                      </p>

                      <h4 id="tip-9" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">9. </span> Escoge bien tu extensión
                      </h4>
                      <p class="mb-8 text-justify">
                        Una de las cosas más importantes al escoger tu dominio, será la extensión, recuerda que existen diversos GTLD's y estos tienen diferentes significados, 
                        también te ayudan a asociar tu marca con diferentes cuestiones como de qué país eres, que clase de organización eres, etc.
                      </p>

                      <h4 id="tip-10" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                        <span class="text-blue-500">10. </span> Metas y objetivos
                      </h4>
                      <p class="mb-8 text-justify">
                        Por último, el nombre de tu dominio debe de ir enfocado en tus metas como marca, debes de pensar a largo plazo cuando escojas el nombre de éste.
                      </p>
                      <p class="mb-8 text-justify">
                        En <span class="text-blue-700 underline"> Renta Dominios</span> podemos asesorarte a escoger el nombre del dominio que más se adapte a tus necesidades.
                      </p>

                      <div>
                        <hr class="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                        <!--<div class="mt-8">
                          Interested in more tips like this? Check out <a class="text-gray-900 underline" href="#0">Introducing the Testing Field Guide</a>.
                        </div>-->
                        <div class="mt-6">
                          <router-link to="/blog" class="inline-flex items-center text-base text-blue-600 font-medium hover:underline">
                            <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 mr-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                              <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                            </svg>
                            <span>Regresar al blog</span>
                          </router-link>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <!-- Article footer -->
              </article>

            </div>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../../partials/Header.vue'
import Footer from './../../partials/Footer.vue'

export default {
  name: 'TipsGoodDomainName',
  components: {
    Header,
    Footer,
  },
  created(){
    document.title="10 consejos para elegir un buen nombre de dominio | Renta Dominios"
  }
}
</script>
