<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pb-12 md:pb-20">

        <!-- CTA box -->
        <div class="bg-blue-600 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl" data-aos="zoom-y-out">

          <div class="flex flex-col lg:flex-row justify-between items-center">

            <!-- CTA content -->
            <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
              <h3 class="h3 text-white mb-2">{{title}}</h3>
              <p class="text-white text-lg opacity-75">{{detail}}</p>
            </div>

            <!-- CTA button -->
            <router-link to="/contact">
              <div>
                <a class="btn text-blue-600 bg-gradient-to-r from-blue-100 to-white" href="#0">{{buttonText}}</a>
              </div>
            </router-link>

          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Cta',
  props: {
    title: String,
    detail: String,
    buttonText: String,
  }
}
</script>