<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <HeroHome />
      <AboutIntro />
      <Stats />
      <FeaturesHome />
      <GetDomainSteps />
      <FeaturesBlocks />
      <FeaturesWorld />
      <Cta 
        title="Adquiere ya tu próximo dominio" 
        detail="Nosotros te tenemos cubierto, contáctanos." 
        buttonText="Empezar ahora" 
      />
      <News />

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import AboutIntro from './../partials/AboutIntro.vue'
import Header from './../partials/Header.vue'
import HeroHome from './../partials/HeroHome.vue'
import FeaturesHome from './../partials/FeaturesHome.vue'
import FeaturesBlocks from './../partials/FeaturesBlocks.vue'
import FeaturesWorld from './../partials/FeaturesWorld.vue'
import News from './../partials/News.vue'
import Cta from './../partials/Cta.vue'
import Footer from './../partials/Footer.vue'
import GetDomainSteps from './../partials/GetDomainSteps.vue'
import Stats from './../partials/Stats.vue'

export default {
  name: 'Home',
  components: {
    AboutIntro,
    Header,
    HeroHome,
    FeaturesHome,
    FeaturesBlocks,
    FeaturesWorld,
    GetDomainSteps,
    News,
    Cta,
    Stats,
    Footer,
  },
  created(){
    document.title="Inicio | Renta Dominios"
  }
}
</script>
