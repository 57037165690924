<template>
  <section class="bg-gradient-to-b from-white to-gray-100">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-32 pb-12 md:pt-40 md:pb-20">    
            <!-- Section header -->
            <div class="max-w-3xl mx-auto text-center pb-12">
                <h1 class="h1 mb-4" data-aos="zoom-y-out">Busca en nuestra lista de dominios.</h1>
                <p class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">Nuestros precios son transparentes. No dudes en contactarnos con tus dudas.</p>
            </div>
            <p><i class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">*Selecciona un dominio para ver su precio*</i></p>
            <br>
            <v-layout row wrap align-center justify-start>
              <p class="pr-4"><b>Organizar por: </b></p>
              <v-layout justify-start row wrap>
                <v-flex v-for="(item, index) in headersData" :key="item.text + index" shrink>
                  <v-btn  
                    class="text-none pa-1 ml-1"
                    :color="(selected[0] !== item.value)? 'black': '#90CAF9'"
                    small
                    align-self-start
                    :text="selected[0] !== item.value"
                    v-if="item.text !== ''"
                    @click="sortBy(item)">
                    <span class="font-weight-light">{{ item.text }}</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-spacer></v-spacer>
            </v-layout>
            <v-text-field
              label="Buscar"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
            <v-data-table
                :headers="selectedObj"
                :items="desserts"
                :search="search"
                v-model="selectedDeserts"
                item-key="id"
                @click:row="selectRow"
                class="text-left"
            >
                <template slot="items" slot-scope="props">
                  <tr 
                    :active="props.selected" 
                  >
                      <td>
                      <v-checkbox
                          v-model="props.selected"
                          primary
                          hide-details
                      ></v-checkbox>
                      </td>
                      <td class="text-xs-left"
                          v-for="(product, productIndex) in selected"
                          :key="product + productIndex"
                          :v-if="selected.includes('product')">
                      {{ props.item[product] }}
                      </td>
                  </tr>
                </template>
            </v-data-table>
            <hr>
            <v-snackbar
                v-model="errorSnackbar"
                color="error"
                :timeout="3000">
            server is not responding
            <v-btn text @click="errorSnackbar = false">
                Close
            </v-btn>
            </v-snackbar>
        </div>
        </div>
        <PricingTables 
          id="pricing-table"
          v-if="selectedDomainName != undefined && selectedDomainName != ''" 
          :domainName="selectedDomainName" 
          :priceOutput="selectedPriceOutputs"
        />
    </section>
</template>

<script>
  import PricingTables from './PricingTables.vue';
  export default {
    components: {
      PricingTables
    },
    props: {
      desserts: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      errorSnackbar: false, // tag to show snackBar
      headersData: [
        {
            text: 'Nombre',
            value: 'Nombre'
        },
        {
            text: 'gTLD',
            value: 'gTLD'
        },
        {
            text: 'Dominio',
            value: 'Dominio'
        },
      ], // default headers data with parameters
      selected: [
        'Nombre',
        'gTLD',
        'Dominio',
      ], // selected headers data (which is shown)
      selectedDeserts: [], // selected deserts to delete
      search: '',
      selectedDomainName: "",
      selectedPriceOutputs: {},
    }),
    computed: {
      selectedObj: function() {
        // make an arr from Objects from filtered headers arr
        return this.filterHeaders();
      },
      headersWithoutLastItem: function() {
        // headers without last item - '' (which contains delete button)
        return this.headersData.slice(0, this.headersData.length - 1);
      },
      selectAllIcon () {
        // toggle select all/select none icon
        if (this.selected.length < 6) return 'check';
        else return 'close';
      },
    },
    methods: {
      filterHeaders: function() {
        // filter headers Arr of Objects the same as sorted Arr
        let selectedHeadersWithDeleteColumn = this.selected.concat(['']);
        let filteredArrofObj = [];
        for (let i = 0; i < selectedHeadersWithDeleteColumn.length; i++) {
          for (let j = 0; j < this.headersData.length; j++) {
            if (selectedHeadersWithDeleteColumn[i] === this.headersData[j].value) {
              filteredArrofObj.push(this.headersData[j]);
            }
          }
        }
        return filteredArrofObj;
      },
      selectAll () {
        // select all columns
        if (this.selected.length < this.headersWithoutLastItem.length) {
          let headerNamesArr = this.headersWithoutLastItem.map(function(item) { return item.value });
          this.selected = headerNamesArr.slice();
        }
        else { this.selected = [] }
      },
      sortBy(item) {
        // make item columns the first
        let index = this.selected.indexOf(item.value);
        if (index === -1) return;
        let deletedElem = this.selected.splice(index, 1);
        this.selected.unshift(deletedElem[0]);
      },
      selectRow(a){
        this.selectedDomainName = a["Dominio"]
        let priceM = a["Precio Renta Mensual USD"]
        let priceMA = a["Precio con descuento"]
        this.selectedPriceOutputs = {
          true: ["$", priceMA, "/mes"],
          false: ["$", priceM, "mes"]
        }
        document.getElementById("pricing-table").scrollIntoView({ behavior: 'smooth', block: 'center' })
      },
    },
    watch: {
      errorDeleting: function() {
        // if it is error on server call snackbar
        this.errorSnackbar = true;
      },
      errorSnackbar: function() {
        // if snackbar finished set deletingError on server to false
        this.setDeletingError(false);
      }
    }
  }
</script>

<style>
  .hideButton {
    visibility: hidden;
    transition: 0s !important;
    -webkit-transition: 0s !important;
  }
  .hideButton > * {
    transition: 0s !important;
    -webkit-transition: 0s !important;
  }
  tr:hover .hideButton {
    visibility: visible;
  }
  tr {
    cursor: pointer;
  }
</style>