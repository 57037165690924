<template>
<section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="py-12 md:py-20 border-t border-gray-200">

            <!-- Section header -->
            <div class="max-w-3xl mx-auto text-center pb-20">
                <h2 class="h2">Preguntas Frecuentes (FAQs)</h2>
            </div>

            <!-- Faqs -->
            <ul class="max-w-3xl mx-auto pl-12">
              <Accordion title="¿Cuál es la duración de la renta de los dominios?" active>
                La duración del arrendamiento de algún dominio en específico, se basará en el contrato que se tenga con el arrendatario en particular. Los períodos son negociables.
              </Accordion>
              <Accordion title="¿Qué es un nombre de dominio?">
                Es la conversión de una dirección IP a un lenguaje comprendido por el usuario. Ejemplo: “aquiestatuweb.com” .Cuando vas a teclear la dirección de una web en tu 
                navegador, en lugar de escribir el número de la IP asignada, que sería muy difícil de memorizar, escribes el nombre de dominio.
              </Accordion>
              <Accordion title="¿Qué es una dirección IP?">
                Una dirección IP es el código numérico que identifica a los ordenadores en Internet. 
                Todos los servidores donde están alojados los sitios web tienen una o más IP. 
                Si vas a tener varios sitios web, tienes la posibilidad de que tengan diferentes IP.
              </Accordion>
              <Accordion title="¿Puedo redírigir mi dominio hacia una página que tengo actualmente?">
                Si, los dominios que rentamos  pueden ser redireccionados, a el dominio o dirección IP que nos indiques.
              </Accordion>
              <Accordion title="¿Cómo rentar un dominio?">
                1. Consulta la lista de dominios que tenemos registrados.
                2. Escoge el dominio que más se adapte a tus necesidades.
                3. Pónte en contacto con nuestro equipo de ventas para poder realizar los trámites de arrendamiento.
              </Accordion>
              <Accordion title="¿Qué tipos de gTLD's existen?">
                Existen diversos tipos de gTLD's, en nuestra lista de dominios, encontrarás los diferentes gTLD's con los que contamos.
              </Accordion>
              <Accordion title="Quiero utilizar varios dominios ¿Tendrán algún descuento por registrar varios dominios simúltaneamiente?">
                Ofrecemos descuentos por registrar varios dominios, así como descuento en el pago de renta anual, contáctanos para poder darte mayor información.
              </Accordion>
              <Accordion title="¿Hay algún servicio adicional con los paquetes?">
                Si, todos nuestros paquetes incluyen asesoría para la instalación y opcionalmente la cotización de desarrollos tecnológicos. (Página web, app, etc.)
              </Accordion>
              <span class="block border-t border-gray-200" aria-hidden="true"></span>
            </ul>
            
        </div>
    </div>
</section>
</template>

<script>
import Accordion from './../utils/Accordion.vue'

export default {
  name: 'Faqs',
  components: {
    Accordion
  },  
}
</script>