<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <!--<FeaturesTable />-->
      <DomainTable :desserts="products"/>
      <GetDomainSteps />
      <!--<TestimonialsCarousel />-->
      <Faqs />
      <Cta 
        title="Contáctanos para resolver tus dudas" 
        detail="En breve nos pondrémos en contacto contigo." 
        buttonText="Contactar" 
      />       

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
//import FeaturesTable from './../partials/FeaturesTable.vue'
//import TestimonialsCarousel from './../partials/TestimonialsCarousel.vue'
import DomainTable from './../partials/DomainTable.vue'
import GetDomainSteps from './../partials/GetDomainSteps.vue'
import Faqs from './../partials/Faqs.vue'
import Cta from './../partials/Cta.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Pricing',
  components: {
    Header,
    /*FeaturesTable,*/
    DomainTable,
    GetDomainSteps,
    /*TestimonialsCarousel,*/
    Faqs,
    Cta,
    Footer,
  },
  data() {
    return {products: require('@/json/dominios.json')['Hoja1'].sort((a,b)=>{
      if (a.Nombre > b.Nombre) {
        return 1;
      }
      if (a.Nombre > b.Nombre) {
        return -1;
      }
      return 0;
    })}
  },
  created(){
    document.title="Precios | Renta Dominios"
  }
}
</script>
