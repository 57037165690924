<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2">Encuentra más información relevante en nuestro blog.</h2>
        </div>

        <!-- Categories
        <div class="mb-12 md:mb-16">
          <ul class="flex flex-wrap justify-center text-sm font-medium -m-2">
            <li class="m-2">
              <a class="inline-flex text-center text-gray-100 py-2 px-4 rounded-full bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out" href="#0">Casos de estudio</a>
            </li>
            <li class="m-2">
              <a class="inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">E-Commerce</a>
            </li>
          </ul>
        </div> -->

        <!-- Articles list -->
        <div class="max-w-sm mx-auto md:max-w-none">
          <div class="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">

            <!-- 1st article -->
            <article class="flex flex-col h-full" data-aos="zoom-y-out">
              <header>
                <router-link to="/blog/what-domain-name" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/blog/whatDomainName/servers.jpg')" width="352" height="198" alt="News 01" />
                  </figure>
                </router-link>
                <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">Casos de estudio</a>
                    </li>
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">E-Commerce</a>
                    </li>
                  </ul>
                </div>
                <h3 class="text-xl font-bold leading-snug tracking-tight">
                  <router-link to="/blog/what-domain-name" class="hover:underline">¿Qué es un nombre de dominio?</router-link>
                </h3>
              </header>
              <footer class="text-sm flex items-center mt-4">
                <div class="flex flex-shrink-0 mr-3">
                  <a class="relative" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/images/logo-1.png')" width="32" height="32" alt="Author 01" />
                  </a>
                </div>
                <div>
                  <span class="text-gray-600">Por </span>
                  <a class="font-medium hover:underline" href="#0">Renta Dominios</a>
                </div>
              </footer>
            </article>
            <article class="flex flex-col h-full" data-aos="zoom-y-out">
              <header>
                <router-link to="/blog/tips-great-name" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/blog/tipsDomainName/thinkingComputer-2.jpg')" width="352" height="198" alt="News 01" />
                  </figure>
                </router-link>
                <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">Casos de estudio</a>
                    </li>
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">E-Commerce</a>
                    </li>
                  </ul>
                </div>
                <h3 class="text-xl font-bold leading-snug tracking-tight">
                  <router-link to="/blog/tips-great-name" class="hover:underline">10 Consejos para elegir un buen nombre de dominio</router-link>
                </h3>
              </header>
              <footer class="text-sm flex items-center mt-4">
                <div class="flex flex-shrink-0 mr-3">
                  <a class="relative" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/images/logo-1.png')" width="32" height="32" alt="Author 01" />
                  </a>
                </div>
                <div>
                  <span class="text-gray-600">Por </span>
                  <a class="font-medium hover:underline" href="#0">Renta Dominios</a>
                </div>
              </footer>
            </article>
            <!--
            <article class="flex flex-col h-full" data-aos="zoom-y-out">
              <header>
                <router-link to="/blog/pros-rent-domain" class="block mb-6">
                  <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                    <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" :src="require('@/images/blog/proRentDomain/pad.jpg')" width="352" height="198" alt="News 01" />
                  </figure>
                </router-link>
                <div class="mb-3">
                  <ul class="flex flex-wrap text-xs font-medium -m-1">
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out" href="#0">Casos de estudio</a>
                    </li>
                    <li class="m-1">
                      <a class="inline-flex text-center text-gray-800 py-1 px-3 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out" href="#0">E-Commerce</a>
                    </li>
                  </ul>
                </div>
                <h3 class="text-xl font-bold leading-snug tracking-tight">
                  <router-link to="/blog/pros-rent-domain" class="hover:underline">8 Ventajas de rentar un dominio</router-link>
                </h3>
              </header>
              <footer class="text-sm flex items-center mt-4">
                <div class="flex flex-shrink-0 mr-3">
                  <a class="relative" href="#0">
                    <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                    <img class="relative rounded-full" :src="require('@/images/logo-1.png')" width="32" height="32" alt="Author 01" />
                  </a>
                </div>
                <div>
                  <span class="text-gray-600">Por </span>
                  <a class="font-medium hover:underline" href="#0">Renta Dominios</a>
                </div>
              </footer>
            </article>
            -->

          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'News',
}
</script>