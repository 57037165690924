<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20 border-t border-gray-200">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h1 class="h2 mb-4">Desarrolla tu negocio de forma global</h1>
          <p class="text-xl text-gray-600 text-justify">
            Un dominio te permite hacer una estrategia al nivel de tus expectativas y te facilitará posicionar tu marca.
          </p>
          <!--<p class="text-xl text-gray-600 mt-6 ">
            Mejora tu imagen, aumenta la confianza de tus clientes y permíteles recordar fácilmente donde encontrarte.
          </p>-->
        </div>

        <!-- World illustration -->
        <div class="flex flex-col items-center pt-16 pb-12 md:pt-20 md:pb-16">
          <div class="relative">
            <!-- Halo effect -->
            <svg class="absolute inset-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none" width="800" height="800" viewBox="0 0 800 800" style="max-width: 200%;" xmlns="http://www.w3.org/2000/svg">
              <g class="fill-current text-gray-400 opacity-75">
                <circle class="pulse" cx="400" cy="400" r="200" />
                <circle class="pulse pulse-1" cx="400" cy="400" r="200" />
                <circle class="pulse pulse-2" cx="400" cy="400" r="200" />
              </g>
            </svg>
            <!-- Globe image -->
            <img class="hidden md:relative md:block rounded-full shadow-xl" :src="require('@/images/planet.png')" width="400" height="400" alt="Planet" />
            <img class="relative md:hidden rounded-full shadow-xl" :src="require('@/images/planet.png')" width="220" height="220" alt="Planet" />
            <!-- Static dots -->
            <svg class="absolute top-0 w-full h-auto" viewBox="0 0 300 300" style="left: 12%;" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter x="-41.7%" y="-34.2%" width="183.3%" height="185.6%" filterUnits="objectBoundingBox" id="world-ill-a">
                  <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
                  <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.439215686 0 0 0 0 0.956862745 0 0 0 0.32 0" in="shadowBlurOuter1" />
                </filter>
                <filter x="-83.3%" y="-68.5%" width="266.7%" height="271.2%" filterUnits="objectBoundingBox" id="world-ill-c">
                  <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
                  <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.439215686 0 0 0 0 0.956862745 0 0 0 0.32 0" in="shadowBlurOuter1" />
                </filter>
                <filter x="-7.3%" y="-23.8%" width="114.5%" height="147.6%" filterUnits="objectBoundingBox" id="world-ill-e">
                  <feGaussianBlur stdDeviation="2" in="SourceGraphic" />
                </filter>
                <ellipse id="world-ill-b" cx="51" cy="175.402" rx="24" ry="23.364" />
                <ellipse id="world-ill-d" cx="246" cy="256.201" rx="12" ry="11.682" />
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="world-ill-f">
                  <stop stop-color="#0070F4" stop-opacity="0" offset="0%" />
                  <stop stop-color="#0070F4" stop-opacity=".64" offset="52.449%" />
                  <stop stop-color="#0070F4" stop-opacity="0" offset="100%" />
                </linearGradient>
              </defs>
              <g transform="translate(0 -.818)" fill="none" fill-rule="evenodd">
                <use fill="#000" filter="url(#world-ill-a)" xlink:href="#world-ill-b" />
                <use fill="#0070F4" xlink:href="#world-ill-b" />
                <use fill="#000" filter="url(#world-ill-c)" xlink:href="#world-ill-d" />
                <use fill="#0070F4" xlink:href="#world-ill-d" />
                <ellipse fill-opacity=".32" fill="#0070F4" cx="293" cy="142.303" rx="8" ry="7.788" />
                <ellipse fill-opacity=".64" fill="#0070F4" cx="250" cy="187.083" rx="6" ry="5.841" />
                <ellipse fill-opacity=".64" fill="#0070F4" cx="13" cy="233.811" rx="2" ry="1.947" />
                <ellipse fill="#0070F4" cx="29" cy="114.072" rx="2" ry="1.947" />
                <path d="M258 256.2l87-29.204" stroke="#666" stroke-width="2" opacity=".16" filter="url(#world-ill-e)" />
                <path d="M258 251.333c111.333-40.237 141-75.282 89-105.136M136 103.364c66.667 4.543 104.667 32.45 114 83.72" stroke="url(#world-ill-f)" stroke-width="2" stroke-dasharray="2" />
              </g>
            </svg>
            <!-- Dynamic dots -->
            <svg class="absolute max-w-full" width="48" height="48" viewBox="0 0 48 48" style="width: 12%;top: 45%; left: 50%;" xmlns="http://www.w3.org/2000/svg">
              <g class="fill-current text-blue-600">
                <circle class="pulse pulse-mini pulse-1" cx="24" cy="24" r="8" />
                <circle class="pulse pulse-mini pulse-2" cx="24" cy="24" r="8" />
                <circle cx="24" cy="24" r="8" />
              </g>
            </svg>
            <svg class="absolute max-w-full" width="48" height="48" viewBox="0 0 48 48" style="width: 12%;top: 19%; left: 46%;" xmlns="http://www.w3.org/2000/svg">
              <g class="fill-current text-blue-600">
                <circle class="pulse pulse-mini" cx="24" cy="24" r="8" />
                <circle class="pulse pulse-mini pulse-2" cx="24" cy="24" r="8" />
                <circle cx="24" cy="24" r="8" />
              </g>
            </svg>
            <!-- Avatars -->
            <div 
              class="md:max-w-none absolute bg-teal-900 shadow rounded-lg py-2 px-2 flex transform animate-float animation-delay-1000" 
              style="width: 55.75%; top: -4%; left: -23.5%;"
            >
              <p class="w-10/12 text-white text-sm px-2"><span class="font-bold">Satisface</span> necesidades de comunicación.</p>
              <svg class="w-8 h-8 mx-auto my-auto text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </div>
            <div 
              class="md:max-w-none absolute bg-white shadow-md rounded-lg py-2 px-2 flex transform animate-float animation-delay-1000" 
              style="width: 50%; bottom: 2%; right: -18%;" 
            >
              <svg class="w-6 h-6 mx-auto my-auto text-teal-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p class="w-11/12 text-teal-900 text-sm px-2 my-auto mr-0 text-right">Aumenta tus ingresos</p>
            </div>
            <div 
              class="md:max-w-none absolute bg-white shadow rounded-lg py-2 px-2 flex transform animate-float animation-delay-1000" 
              style="width: 55%; top: -8%; right: -22%;" 
            >
              <svg class="w-6 h-6 mx-auto my-auto text-teal-900" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                <path fill-rule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clip-rule="evenodd" />
              </svg>
              <p class="w-11/12 text-teal-900 text-sm px-2 my-auto mr-0">Usa palabras que vendan</p>
            </div>
            <!-- Blue icon -->
            <div class="absolute max-w-full bg-blue-600 rounded-full shadow-xl" style="top: 15%; right: 0%;">
              <img :src="require('@/images/logo-1.png')" class="h-12 w-12 rounded-full"/>
            </div>
            <!-- Black icon -->
            <svg class="absolute max-w-full w-16 h-auto rounded-full shadow-xl" viewBox="0 0 64 64" style="width: 16%; bottom: 12%; left: -17%;" xmlns="http://www.w3.org/2000/svg">
              <circle class="fill-current text-gray-800" cx="32" cy="32" r="32" />
              <g transform="translate(.582 .055)" fill="none" fill-rule="evenodd">
                <g transform="rotate(45 -6.35 52.885)">
                  <path class="fill-current text-gray-300" d="M5-1h2v7H5zM5 16h2v7H5z" />
                  <circle class="stroke-current text-white" stroke-width="2" stroke-linecap="square" cx="6" cy="11" r="6" />
                </g>
              </g>
            </svg>
          </div>
        </div>

        <!-- Items -->
        <div class="max-w-sm mx-auto grid gap-8 md:gap-16 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-5xl pt-8 md:pt-12">

          <!-- 1st item -->
          <div class="text-center">
            <div class="h3 mb-1">2020</div>
            <div class="text-gray-600">1,964,515 dominios registrados hasta octubre del 2020.</div>
          </div>

          <!-- 2nd item -->
          <div class="text-center">
            <div class="h3 mb-1">68%</div>
            <div class="text-gray-600">
              De los dominios registrados en México son 
              <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">.com o .com.mx</span>
            </div>
          </div>

          <!-- 3rd item -->
          <div class="text-center">
            <div class="h3 mb-1">MX</div>
            <div class="text-gray-600">
              Si obtienes un dominio 
              <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400"> .mx </span>
              tendrás identificación directa con México.
            </div>
          </div>

          <!-- 4th item -->
          <div class="text-center">
            <div class="h3 mb-1">39%</div>
            <div class="text-gray-600">De la población mexicana adquirirá bienes o servicios en línea en 2021.</div>
          </div>

          <!-- 5th item -->
          <div class="text-center">
            <div class="h3 mb-1">64.5M</div>
            <div class="text-gray-600">En México hoy hay al menos 64.5 millones de internautas con alguna red social.</div>
          </div>

          <!-- 6th item -->
          <div class="text-center">
            <div class="h3 mb-1">60%</div>
            <div class="text-gray-600">De aumento en ventas promedio mediante el marketing digital.</div>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Stats',
}
</script>