<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-12 pb-12 md:pt-16 md:pb-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-left pb-12 md:pb-16">
          <h2 class="h2 mb-8 text-justify">AVISO DE PRIVACIDAD.</h2>
          <p class="text-xl text-gray-600 text-justify">
            “Grupo Creativo Kreaidea S.A. de C.V.” en lo sucesivo “Kreaidea”, con domicilio en
            Bosques de Lavanda 38 – 03. Col. Bosque Esmeralda. Atizapán de Zaragoza.
            Estado de México. C.P: 52930, hace de su conocimiento que los Datos Personales de
            Identificación (incluyendo la geo localización), contacto y financieros (denominados como
            “Datos Personales”), son importantes para Kreato, es por ello que se toma con precaución
            y discreción de su Datos Personales proporcionados.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Conforme a la actividad que realizamos, obtenemos información de nuestros usuarios y
            visitantes en el sitio Web, tanto en la plataforma electrónica como en el sistema de Pagos
            Online.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Como una referencia sobre los Datos Personales se recomienda revisar el Glosario.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Los Datos Personales obtenidos se recaban para las siguientes finalidades, principalmente:
          </p>
          <ul class="font-medium -my-2">
            <!-- 1st level -->
            <li class="py-2">
              Inscribirse en el Sitio Web;
            </li>
            <li class="py-2">
              Creación de una cuenta para la venta de mercancía con entrega a domicilio;
            </li>
            <li class="py-2">
              Compra en línea;
            </li>
            <li class="py-2">
              Ayudar al comprador y vendedor a tener contacto directo según la modalidad de su compra;
            </li>
            <li class="py-2">
              Actualización de nuestras bases de datos;
            </li>
            <li class="py-2">
              Apartar productos;
            </li>
            <li class="py-2">
              Levantar pedido así como la entrega del producto;
            </li>
            <li class="py-2">
              Notificar sobre el estado de la mercancía;
            </li>
            <li class="py-2">
              Realizar pago y proceso de facturación;
            </li>
            <li class="py-2">
              Atender las dudas y/o quejas, o sugerencias, devoluciones o aclaraciones de los
              usuarios;
            </li>
            <li class="py-2">
              Analizar las páginas visitadas para mejor las ofertas y contenidos;
            </li>
            <li class="py-2">
              Compartir los Datos Personales (incluyendo el e-mail así como la información
              financiera) con los proveedores de servicios o las empresas que contribuyan a
              mejorar las operaciones a través de Kreato, como lo son servicios de transporte,
              medios de pago, intermediarios de gestión de pagos, entidades públicas y/o
              privadas que brindan información crediticia.
              Cabe señalar que Kreato, no se hace responsables por el uso indebido de los Datos
              Personales del usuario que hagan estas compañías o sitios de internet.
            </li>
            <li class="py-2">
              Dar cumplimiento a nuestras políticas, procedimientos lineamientos y protocolos,
              entregando información a auditores nacionales o extranjeros, si así se requiere.
            </li>
          </ul>
          <p class="text-xl text-gray-600 text-justify">
            Los Datos Personales podemos también tratarlos para:
          </p>
          <ul class="font-medium -my-2">
            <!-- 1st level -->
            <li class="py-2">
              Enviarle promociones, publicidad, tanto de Kreato, como de empresas subsidiarias,
              filiales e incluso de nuestros proveedores.
            </li>
            <li class="py-2">
              Para estudios de mercado con la finalidad de conocer sus hábitos de consumo y
              gustos.
            </li>
          </ul>
          <p class="text-xl text-gray-600 text-justify">
            En algunos casos en que hayan pedido que retiremos los Datos Personales de Kreato, esto
            no será posible, debido a resolver reclamaciones o incidencias y solucionarlos, por el
            tiempo que determine la normativa aplicable.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Es responsabilidad de los usuarios actualizar sus Datos Personales periódicamente
            conforme vayan cambiando.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Al momento en que el usuario registre su cuenta, y/o realice compra, recabaremos sus
            Datos Personales y obtendremos su consentimiento para los efectos establecidos en el
            presente Aviso de Privacidad.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Le informamos que podemos llegar a transferir sus Datos Personales a terceros en los
            siguientes supuestos, previo consentimiento por cualquier vía de contacto:
          </p>
          <ul class="font-medium -my-2">
            <!-- 1st level -->
            <li class="py-2">
              Enviar ofertas y/o publicidad;
            </li>
            <li class="py-2">
              Elaborar estudios de mercado;
            </li>
            <li class="py-2">
              Instituciones Financieras, las cuales podrán otorgarles productos y/o servicios
              financieros para realizar la actividad necesaria para tales fines.
            </li>
          </ul>
          <p class="text-xl text-gray-600 text-justify">
            Kreato no recabará Datos Personales Sensibles como lo son: religiosos, sindicales, raciales,
            étnicos, de entre otros. (Glosario).
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Adicionalmente podremos transferir sus Datos Personales, sin necesidad de su
            consentimiento a:
          </p>
          <ul class="font-medium -my-2">
            <!-- 1st level -->
            <li class="py-2">
              Empresas nacionales o internacionales del mismo grupo, subsidiarias y/o filiales de
              Kreato y,
            </li>
            <li class="py-2">
              Terceros que nos apoyen con lo relacionado con la compra en línea.
            </li>
          </ul>
          <p class="text-xl text-gray-600 text-justify">
            Estas transferencias de datos personales serán realizadas con todas las medidas de
            seguridad de conformidad con la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares y su Reglamento.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            El Usuario tendrá acceso libre al sitio, sin necesidad de tener un Seudónimo que los
            identifique con una Clave Personal.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Sin embargo, nuestros servicios sólo están disponibles para mayores de edad con plena
            capacidad jurídica para contratar, sin embargo los que no cumplan con ello, podrán
            realizar compras a través de sus padres o tutores, según corresponda.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Kreato podrá recopilar información tales como: la dirección IP, dirección MAC, tipo de
            navegador de usuario, nombre del dominio y páginas especificas del sitio a través de las
            2cuales tuvo acceso, así como el tiempo de interacción de la página de internet, “Cookies” y
            “web beacons” con la finalidad de llenar estadistas del comportamiento de navegación de
            nuestros usuarios.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            En el caso de empleo de los Cookies que son pequeños archivos que se instalan en un
            disco rígido con duración limitada, se utilizan para analizar las páginas navegadas por el
            visitante o usuario, así como conocer los intereses, búsquedas y comportamiento de los
            usuarios que visitan el sitio Web, también para cumplir las reglas de seguridad del sitio.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            En el caso del empleo de Cookies, en el botón de ayuda que se encuentra en la barra de
            herramientas de la mayoría de los navegadores le indicará como evitar aceptar nuevos
            Cookies, y cómo hacer que el navegador le notifique cuando recibe un nuevo Cookie o
            como deshabilitar todas las Cookies.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Adicionalmente se pueden encontrar Cookies en otros sistemas instalados por terceros
            dentro de nuestro Sitio Web, por lo que Kreato no se hace responsable por el uso de ellos
            por parte de terceros.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Al momento de comprar un producto en línea, se solicitaran datos bancarios los cuales de
            manejarán de forma segura, integra y confidencial; para asegurar que se encuentra en un
            entorno protegido, asegúrese de que aparezca una “S” en la barra (“https”://)
            Kreato no acepta las conductas conocidas como “spamming”, por lo que queda
            absolutamente prohibido el envío de mensajes de cualquier naturaleza entre los usuarios,
            por lo que podrá suspender o inhabilitar definitivamente a aquellos usuarios que violen
            esta política.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            El presente Aviso de Privacidad podrá sufrir actualizaciones, cambios y/o modificaciones
            derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los
            productos o servicios que ofrecemos, de nuestras prácticas de privacidad; de cambios de
            nuestro modelo de negocio o por otras causas.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el
            presente Aviso de Privacidad, a través de nuestra página de internet.
            Procedimiento para dar Atención a Derechos ARCO.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Usted tiene el derecho de conocer qué Datos Personales tenemos de usted, para qué los
            utilizamos y las condiciones de uso que les damos (Acceso). Asimismo, es su derecho
            solicitar la corrección de su información personal en caso de qué este desactualizada, sea
            inexacta o incompleta (Rectificación); que eliminemos de nuestro registro o base de datos
            cuando considere que los mismos no están siendo utilizados conforme a los principios,
            deberes y obligaciones previstas por la normativa (Cancelación), así como oponerse al uso
            de los Datos Personales para fines específicos (Oposición). Estos derechos se conocen
            como Derechos ARCO.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Para iniciar el proceso de solicitud de Derechos ARCO, (Acceso, Rectificación, Cancelación,
            y Oposición de Datos Personales), o para la revocación del consentimiento al tratamiento
            de Datos Personales, podrá hacerlo:
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Ingresando un escrito en nuestro Departamento de Privacidad, ubicado en la calle de
            Bosques de Lavanda 38 – 03. Col. Bosque Esmeralda. C.P: 52930. Atizapán de
            Zaragoza. Estado de México, o enviar correo electrónico a la dirección
          </p>
          <p class="text-xl text-gray-600 text-justify">
            contacto@kreaidea.com.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            En el que se solicitará Nombre, correo electrónico y domicilio del Titular,
            Identificación Oficial,
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Descripción clara de la información personal sobre la que recaerán los Derechos ARCO.
            Cualquier documento o información que facilite su localización.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            El responsable atenderá la petición en Titular en un término de 20 (veinte) días hábiles
            contados a partir de la fecha de recepción del escrito antes señalado y se le solicitará la
            información o documentación necesaria para darle seguimiento, así como la acreditación
            de la identidad, por lo que usted contará con 10 (diez) días hábiles para atender el
            requerimiento, de lo contario su solicitud se tendrá por no presentada.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            En un plazo posterior a 10 (diez) días hábiles, el responsable emitirá una resolución que le
            será notificada por los medios de contacto que haya establecido en su solicitud; en caso
            de que la resolución sea procedente, se tendrá un lapso de 15 (quince) día hábiles para
            adoptar dicha resolución.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Con el objeto de que usted pueda limitar el uso y divulgación de su información personal,
            le ofrecemos:
          </p>
          <p class="text-xl text-gray-600 text-justify">
            Su inscripción en el Registro Público para Evitar la Publicidad, que está a cargo de la
            Procuraduría Federal de Consumidor, (PROFECO) con la finalidad de que sus datos
            personales no sea utilizados para recibir publicidad o promociones de empresas de bienes
            y servicios.
          </p>
          <p class="text-xl text-gray-600 text-justify">
            En caso de que considere que la Protección de sus Datos Personales no ha sido
            debidamente atendida, le informamos que tiene el derecho de acudir ante el Instituto
            Nacional de Transparencia, Acceso a la Información y Protección de los Datos Personales
            (INAI).
          </p>
          <p class="text-xl text-gray-600 text-justify">
            GLOSARIO.
          </p>
          <ul class="font-medium -my-2">
            <!-- 1st level -->
            <li class="py-2">
              Aviso de Privacidad. Documento físico, electrónico o en cualquier otro formato generado por el
              responsable que es puesto a disposición del titular, previo al tratamiento de sus datos
              personales, de conformidad con el artículo 15 de la Ley Federal de Protección de Datos
              Personales en Posesión de los Particulares.
            </li>
            <li class="py-2">
              Bases de datos. El conjunto ordenado de datos personales referentes a una persona identificada
              o identificable.
            </li>
            <li class="py-2">
              Consentimiento. Manifestación de la voluntad del titular de los datos mediante la cual se efectúa
              el tratamiento de los mismos.
            </li>
            <li class="py-2">
              Cookies. Son un archivo de datos que se almacena en un disco duro del equipo de cómputo o
              del dispositivo de comunicaciones electrónicas, de un usuario al navegar un sitio de internet
              específico, el cual permite intercambiar información de estado entre dicho sitio y el navegador
              del usuario. La información de estado puede revelar medios de identificación de sesión,
              autenticidad o preferencias del usuario, así como cualquier otro dato almacenado por el
              navegador respecto del sitio de internet.
            </li>
            <li class="py-2">
              Datos personales. Cualquier información concerniente a una persona física identificada o
              identificable.
            </li>
            <li class="py-2">
              Datos personales sensibles. Aquellos datos personales que afecten a la esfera más íntima de su
              titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave
              para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como
              origen racial o étnico, estado de salud presente y futuro, información genética, creencias
              religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.
              Días: Días hábiles.
            </li>
            <li class="py-2">
              Derechos ARCO. Son los derechos de Acceso, Rectificación, Cancelación u Oposición, respecto
              de los datos personales
            </li>
            <li class="py-2">
              Encargado. La persona física o jurídica que sola o conjuntamente con otras trate datos
              personales por cuenta del responsable.
            </li>
            <li class="py-2">
              Fuente de acceso público. Aquellas bases de datos cuya consulta puede ser realizada por
              cualquier persona, sin más requisito que, en su caso, el pago de una contraprestación, de
              conformidad con lo señalado por el Reglamento de esta Ley.
            </li>
            <li class="py-2">
              Instituto. Instituto Federal de Acceso a la Información y Protección de Datos, a que hace
              referencia la Ley Federal de Transparencia y Acceso a la Información Pública Gubernamental.
              Ley. Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
            </li>
            <li class="py-2">
              Reglamento. El Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de
              los Particulares.
            </li>
            <li class="py-2">
              Responsable. Persona física o moral de carácter privado que decide sobre el tratamiento de
              datos personales.
            </li>
            <li class="py-2">
              Spamming. Envío de correo basura.
            </li>
            <li class="py-2">
              Tercero. La persona física o moral, nacional o extranjera, distinta del titular o del responsable de
              los datos.
            </li>
            <li class="py-2">
              Titular. La persona física a quien corresponden los datos personales.
            </li>
            <li class="py-2">
              Tratamiento. La obtención, uso, divulgación o almacenamiento de datos personales, por
              cualquier medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento,
              transferencia o disposición de datos personales.
            </li>
            <li class="py-2">
              Transferencia. Toda comunicación de datos realizada a persona distinta del responsable o
              encargado del tratamiento.
            </li>
            <li class="py-2">
              Web beacons. Son una imagen visible u oculta insertada dentro de un sitio web o correo
              electrónico, que se utiliza para monitorear el comportamiento del usuario en estos medios. A
              través de éstos se puede obtener información como la dirección IP de origen, navegador
              utilizado, sistema operativo, momento en que se accedió a la página y en el caso del correo
              electrónico, la asociación de los datos anteriores con el destinatario.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Privacy',
    components: {
    },
    created(){
        document.title="Política de Privacidad | Renta Dominios"
    }
}
</script>