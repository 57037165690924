<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-12 pb-12 md:pt-16 md:pb-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h1 class="h2 mb-8 text-justify">Facilitamos el desarrollo de proyectos memorables, con la renta de nuestros dominios exclusivos.</h1>
          <p class="text-xl text-gray-600 text-justify">
            Estamos transformando la forma en la que se adquieren y utilizan dominios premium, 
            sin necesidad de gastar grandes cantidades para la adquisición de estos.
          </p>
        </div>

        <figure class="flex justify-center items-start">
          <img class="rounded shadow-xl" :src="require('@/images/webdesign-1.jpg')" width="768" height="432" alt="About us" />
        </figure>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutIntro',
}
</script>