<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">
            <div class="max-w-3xl mx-auto lg:max-w-none">
              <article>
                <!-- Article header -->
                <header class="max-w-3xl mx-auto mb-20">
                  <!-- Title -->
                  <h1 class="h1 text-center mb-2">¿Qué es un nombre de dominio? y ¿Para qué sirve?</h1>
                </header>
                <!-- Article content -->
                <div class="lg:flex lg:justify-between" data-sticky-container>

                  <!-- Sidebar -->
                  <aside class="relative hidden lg:block w-64 mr-20 flex-shrink-0">
                    <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>
                      <h4 class="text-lg font-bold leading-snug tracking-tight mb-4">Tabla de contenidos</h4>
                      <ul class="font-medium -my-1">
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#introduction">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Introducción</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#composition">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>¿Cómo se compone?</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#domainTypes">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Tipos de dominios</span>
                          </a>
                        </li>
                        <li class="py-1">
                          <a class="flex items-center hover:underline" href="#conclusion">
                            <svg class="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                            </svg>
                            <span>Conclusión</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </aside>

                  <!-- Main content -->
                  <div>

                    <!-- Article meta -->
                    <div class="flex items-center mb-6">
                      <div class="flex flex-shrink-0 mr-3">
                        <a class="relative" href="#0">
                          <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                          <img class="relative rounded-full" :src="require('@/images/logo-1.png')" width="32" height="32" alt="Author 04" />
                        </a>
                      </div>
                      <div>
                        <span class="text-gray-600">Por </span>
                        <a class="font-medium">Renta Dominios</a>
                        <span class="text-gray-600"> · 9 de Diciembre, 2020</span>
                      </div>
                    </div>
                    <hr class="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                    <!-- Article body -->
                    <div class="text-lg text-gray-600">
                      <p id="introduction" class="mb-8 text-justify" style="scroll-margin-top: 100px;">
                        Es el nombre único e irrepetible, que se le da a un sitio en internet, para que las ideas de negocio sean identificadas de forma cómoda y sencilla por internautas y los clientes de la marca.
                      </p>
                      <p class="mb-8 text-justify">
                        <i>Domain Name System</i> o DNS, es el sistema que brinda la dirección a servidores y páginas web. El sistema de nombres de dominio brinda a cada servidor, una dirección única, fácil de recordar y deletrear.
                      </p>
                      <figure class="mb-8">
                        <img class="w-full rounded" :src="require('@/images/blog/whatDomainName/servers.jpg')" width="768" height="432" alt="Blog single" />
                      </figure>

                      <!--Seccion 2-->
                      <h4 id="composition" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">¿Cómo se compone el nombre de un dominio?</h4>
                      <p class="mb-8 text-justify">
                        Analicemos el siguiente enlace: <u><i>www.rentadominios.com</i></u>
                      </p>
                      <p class="mb-8 text-justify">
                        Los nombres de dominios deben ser únicos, y los descriptores van separados por puntos. A la extrema izquierda se encuentra el prefijo <i class="font-medium text-gray-700">"www"</i> <span class="text-xs">(World Wide Web)</span> que hace referencia al servidor. 
                        <i class="font-medium text-gray-700">"rentadominios"</i> <span class="text-xs">(dominio de nivel medio)</span> ubicado en el centro de la dirección, conforma lo que habitualmente denominamos como el <i class="font-medium text-gray-700">"nombre de dominio"</i> o <i class="font-medium text-gray-700">"dominio"</i>. 
                        Por último, en la extrema derecha se encuentra el <i class="font-medium text-gray-700">"com"</i>. Esta parte es denominada como Dominio de Nivel Superior y sirve para reflejar la ubicación geográfica o el propósito de la organización o entidad.
                      </p>

                      <!--Seccion 3-->
                      <h4 id="domainTypes" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">¿Cuáles son los tipos de dominios de nivel superior?</h4>
                      <p class="mb-8 text-justify">
                        A continuación se muestra una lista con los principales tipos de dominio de nivel superior:
                      </p>
                      <ul class="list-disc list-inside mb-8">
                        <li class="mb-1">
                          <strong class="font-medium text-gray-900">Genéricos</strong> (gTLD): 
                          Utilizados sin ninguna restricción, se componen normalmente de tres letras 
                          (<span class="text-blue-400">.com, </span>
                          <span class="text-blue-400">.edu, </span>
                          <span class="text-blue-400">.net, </span>
                          etc.).
                        </li>
                        <li class="mb-1">
                          <strong class="font-medium text-gray-900">Geográficos</strong> (ccTLD): 
                          Hacen referencia a un país o territorio
                          (<span class="text-blue-400">.mx </span> para México, 
                          <span class="text-blue-400">.es </span> para España, 
                          <span class="text-blue-400">.ar </span> para Argentina,
                          etc.).
                        </li>
                      </ul>
                      <p class="mb-8 text-justify">
                        Adicionalmente es relevante mencionar que existen los <i class="font-medium text-gray-700">subdominios</i>, los cuales se encuentran entre el prefijo y el nombre del dominio, por ejemplo <i class="font-medium text-gray-700">www.<u>pagos</u>.rentadominios.com</i>. 
                        Muchas navegadores hoy en día omiten el <i>www</i> por lo que <i class="font-medium text-gray-700"><u>pagos</u>.rentadominios.com</i> es igualmente válido.
                      </p>
                      <p class="mb-8 text-justify">
                        <a class="inline-flex items-center text-base text-blue-600 font-medium hover:underline" href="/">
                          <span>Conóce más sobre rentadominios</span>
                          <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                          </svg>
                        </a>
                      </p>

                      <!--Seccion 4-->
                      <h4 class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Un nombre de dominio no es lo mismo que la dirección IP</h4>
                      <p class="mb-8 text-justify">
                        Un nombre de dominio está destinado a ser un <i class="font-medium text-gray-700">"sobrenombre"</i>. La dirección técnica verdadera de un anfitrión en la web es su dirección del Protocolo de Internet <span class="text-xs">(mejor conocida como dirección IP)</span>.
                      </p>
                      <h4 class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">Un nombre de dominio no es lo mismo que una URL</h4>
                      <p class="mb-8 text-justify">
                        Un nombre de dominio es parte de una dirección más grande llamada URL. Una URL entra en más detalles que el nombre del dominio, ya que esta nos proporciona más información la cual incluye la dirección de una página específica, el nombre de la carpeta, el nombre de la máquina y el lenguaje del protocolo.
                      </p>

                      <!--Conclusión-->
                      <h4 id="conclusion" class="h4 text-gray-900 mb-4" style="scroll-margin-top: 100px;">¿Cuáles son los beneficios de rentar tu dominio?</h4>
                      <ul class="list-disc list-inside mb-8">
                        <li class="mb-1">
                          Es el primer paso para llevar tu marca a <strong class="font-medium text-gray-900">internet.</strong>
                        </li>
                        <li class="mb-1">
                          Profesionaliza tu marca con <strong class="font-medium text-gray-900">correos personalizados.</strong>
                        </li>
                        <li class="mb-1">
                          Organiza tus productos con <strong class="font-medium text-gray-900">subdominios.</strong>
                        </li>
                        <li class="mb-1">
                          <strong class="font-medium text-gray-900">Facilita</strong> la promoción de tu marca.
                        </li>
                        <li class="mb-1">
                          <strong class="font-medium text-gray-900">Destaca</strong> entre tu competencia.
                        </li>
                        <li class="mb-1">
                          <strong class="font-medium text-gray-900">Mejora tu SEO</strong> con dominios premium fáciles de recordar.
                        </li>
                      </ul>

                      <div>
                        <hr class="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                        <!--<div class="mt-8">
                          Interested in more tips like this? Check out <a class="text-gray-900 underline" href="#0">Introducing the Testing Field Guide</a>.
                        </div>-->
                        <div class="mt-6">
                          <router-link to="/blog" class="inline-flex items-center text-base text-blue-600 font-medium hover:underline">
                            <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 mr-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                              <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                            </svg>
                            <span>Regresar al blog</span>
                          </router-link>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <!-- Article footer -->
              </article>

            </div>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../../partials/Header.vue'
import Footer from './../../partials/Footer.vue'

export default {
  name: 'WhatDomainName',
  components: {
    Header,
    Footer,
  },
  created(){
    document.title="¿Qué es un nombre de dominio? | Renta Dominios"
  }
}
</script>
