<template>
  <section>
    <h1 class="h1 text-center mt-16 mb-8">Pasos para rentar un dominio:</h1>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pb-12 pt-8 md:py-16 border-t border-gray-200">

        <!-- Items -->
        <div class="relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-5 items-start md:max-w-2xl lg:max-w-none">

          <div class="absolute top-1/2 w-full h-1 bg-gradient-to-r from-blue-500 to-teal-300 hidden lg:block" aria-hidden="true"></div>

          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-600 text-white font-bold mb-3 p-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1"><span class="text-blue-700">1.</span> Buscar</h4>
            <p class="text-gray-600 text-center" style="height: 9rem">
              Revisa los dominios que tenemos y encuentra el que más se adecue a tus necesidades.
            </p>
          </div>

          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-600 text-white font-bold mb-3 p-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
            </div>
            <router-link to="/contact" class="cursor-pointer">
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1"><span class="text-blue-700">2.</span> Contáctanos</h4>
            </router-link>
            <p class="text-gray-600 text-center" style="height: 9rem">
              Ponte en contacto con nuestro equipo de ventas con el dominio que te interesa rentar.
            </p>
          </div>

          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-600 text-white font-bold mb-3 p-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
              </svg>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1"><span class="text-blue-700">3.</span> Formalidades</h4>
            <p class="text-gray-600 text-center" style="height: 9rem">
              Entrega la documentación que nuestro equipo de ventas te solicitará para poder continuar.
            </p>
          </div>

          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-600 text-white font-bold mb-3 p-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
              </svg>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1"><span class="text-blue-700">4.</span> Firma y envía</h4>
            <p class="text-gray-600 text-center" style="height: 9rem">
              Firma y envía el contrato que establece los tiempos y el costo del dominio que vas a rentar.
            </p>
          </div>

          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-600 text-white font-bold mb-3 p-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clip-rule="evenodd" />
              </svg>
            </div>
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1"><span class="text-blue-700">5.</span> Crece</h4>
            <p class="text-gray-600 text-center" style="height: 9rem">
              Crece y alcanza todas tus metas con el mejor dominio 
              <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">Premium </span>
               para tu negocio.
            </p>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GetDomainSteps',
}
</script>